import React from "react"
import styled from "styled-components"

import GlobalStyle from "../components/globalStyle"
import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import Footer from "../components/footer"
import SEO from "../components/seo"

import Hero from "../images/about.jpg"
import Stuart from "../images/stuart.jpg"
import Parnell from "../images/parnell.jpg"

const Content = styled.div`
  padding: 1rem 3rem;
  max-width: 1100px;
  margin: 0 auto;
  line-height: 32px;

  & p {
    font-size: 1.5rem;
    margin: 2rem 0;
  }

  & h2 {
    font-size: 2rem;
    margin: 1rem 0;
  }
  & h3 {
    font-size: 1.8rem;
    margin: 1rem 0;
  }
`

const Profile = styled.div`
  width: 400px;
  margin: 120px 1rem;
  background-color: #fff;
  padding: 0 20px 20px;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.075);
  text-align: center;

  & p {
    margin: 1rem 0;
  }
`

const Avatar = styled.div`
  border-radius: 100px;
  overflow: hidden;
  height: 150px;
  width: 150px;
  position: relative;
  margin: auto;
  top: -60px;
  box-shadow: 0 0 0 13px #f0f0f0;

  & img {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 100px;
  }
`

const Profiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export default ({ data }) => {
  return (
    <Layout>
      <SEO title="Expandrr - About us" />
      <PageHeader image={Hero} text="About Us"></PageHeader>
      <GlobalStyle />
      <Content>
        <h2>All About Us</h2>
        <p>
          Expandrr is a project that works to help businesses grow through
          offshoring, outsourcing, or freelancing in the Philippines.
        </p>
        <p>
          Growing a team can be difficult and with increased overheads, salary
          raises, and other aspects involved - may just not be feasible where
          you are. We help companies (a) understand how moving offshore can help
          them, (b) familiarize themselves with the various forms of expansion,
          (c) create a strategy that’s right for their business.
        </p>
        <p>
          The Philippines has evolved. It’s become a major hub for offshore
          development, and growth in the tech and startup sectors will ensure
          that it continues to do so. A number of outsourcing companies have
          come about, and each has a unique quirk - whether it be tech, legal,
          advertising, etc..{" "}
        </p>
        <p>
          We aren’t a provider ourselves, so our incentive isn’t to get you
          seated or hiring through us. We simply want to help you see your
          options then pick the strategy that suits your needs.
        </p>
        <p>Let us help you today.</p>
      </Content>
      <Profiles>
        <Profile>
          <Avatar>
            <img src={Stuart} height="150" width="150" alt="Stuart Mitchell" />
          </Avatar>
          <h2>Stuart Mitchell</h2>
          <h4>Co-Founder</h4>
          <p>
            Software engineering has been my career for the last twelve years,
            and in that time I've come to the understanding that a team-first
            mentality will lead to great outcomes. Through the years, I've had
            the opportunity to work with both enterprise teams with various
            roles as well as small startups looking to grow.
          </p>
          <p>
            My current team has moved from an onshore model to an offshore
            model, and I've been able to manage this team for the last three
            years. Through this I have been - and continue to - learn about
            scaling efficient teams, understanding how culture impacts
            development, and the factors associated with moving offshore.
          </p>
        </Profile>
        <Profile>
          <Avatar>
            <img src={Parnell} height="150" width="150" alt="Parnell Piano" />
          </Avatar>
          <h2>Parnell Piano</h2>
          <h4>Co-Founder</h4>
          <p>
            While my experience has brought me through various roles and spaces
            - ultimately, I work on practicing a core tenet that has influenced
            my studies and professional life. Communication.
          </p>
          <p>
            I believe that communication plays a vital role in our everyday
            interactions and how we see the world. A lot of my work in the past
            has focused on bridging gaps between global workspaces and ensuring
            that people come away with clear outcomes while learning to practice
            empathy.
          </p>
        </Profile>
      </Profiles>
      <Footer></Footer>
    </Layout>
  )
}
